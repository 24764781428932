import React from "react"
import { graphql, Link } from "gatsby"
import GlobalStyle from "../styles/global"
import Helmet from "react-helmet"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { ThemeProvider } from "styled-components"
import theme from "../styles/theme"
// import PostCard from "../components/PostCard"
// import HomeJsonLd from "../components/json/HomeJsonLd"

import styled from "styled-components"
import MobileFooter from "../components/MobileFooter"
import Header from "../components/Header"
import Footer from "../components/Footer"
import ModalWindow from "../components/ModalWindow"

import testJPG1 from "../png/library/guide.png"
import testJPG2 from "../png/library/exam.png"
import testJPG3 from "../png/library/teacher.png"
import testJPG4 from "../png/library/preposition.png"
import testJPG5 from "../png/library/abroad.png"
import testJPG6 from "../png/library/class-management.png"

import { GatsbyImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 1rem 0;
`

const Content = styled.div`
  margin: 0 auto 10px;
  text-align: center;

  &:nth-child(odd) {
    padding-left: 20px;
    padding-right: 10px;
  }
  &:nth-child(even) {
    padding-left: 10px;
    padding-right: 20px;
  }

  /* .card {
    width: 150px;
    max-width: 150px;
    height: 230px;
    border-radius: 10px;
    background: ${(props) => props.background};
  } */
`

const Card = styled.img`
  /* object-fit:cover; */
  width: 150px;
  max-width: 150px;
  height: 230px;
  border-radius: 10px;
  background: ${(props) => props.background};
`

const Title = styled.h2`
  font-size: 12px;
  margin: 0;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: bold;
  color: #000;
  text-align: left;
  padding-left: calc(50%-140px);
`

const Description = styled.p`
  position: relative;
  font-size: 8px;
  margin: 5px auto;
  /* transform: scale(0.9); */
  transform-origin: 0 0;
`

class Library extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
      cardContent: [],
      postContent: [],
    }
  }

  render() {
    // const { data } = this.props
    // const siteTitle = data.site.siteMetadata.title
    const { location, title } = this.props
    const isLibrary = true
    const cardContents = [
      {
        img: testJPG1,
        title: "指導案という道標",
        description: "授業を進めるための設計図/羅針盤",
        background: "#00A1F2",
      },
      {
        img: testJPG2,
        title: "受験戦争は続く",
        description: "毎年激化していくセンター試験の現実",
        background: "#F9A610",
      },
      {
        img: testJPG3,
        title: "教採合格までの道",
        description: "スケジューリングが鍵",
        background: "#2E8B57",
      },
      {
        img: testJPG4,
        title: "前置詞・副詞を極める",
        description: "イメージで理解せよ",
        background: "#f54958",
      },
      {
        img: testJPG5,
        title: "語学留学",
        description: "人生への自己投資",
        background: "#00065d",
      },
      {
        img: testJPG6,
        title: "学級運営を考える",
        description: "1 on N マネジメント",
        background: "#b2b2b2",
      },
    ]

    const postContents = [
      [
        {
          title: "【学習指導案】なぜ書けない？【４つの理由と解決策】",
          url: "/reason-why-you-can-not-write/",
        },
        {
          title: "現役教師が教える学習指導案の書き方【例・テンプレート付き】",
          url: "/guidance-plan/",
        },
        {
          title: "【学習指導案が見つかる】おすすめのデータベース５選",
          url: "/database-of-guidance/",
        },
      ],
      [
        {
          title: "2019年、私大入試はもっと厳しくなるって知ってた？",
          url: "/difficulty-of-private-univ-exam/",
        },
        {
          title: "【入試厳格化の影響!?】指定校・AO入試も難しくなります",
          url: "/difficulty-of-recommendation-entrance-exam-and-admissions-office/",
        },
        {
          title: "【速報：2020年大学一般入試】後半戦に向けて【今年も厳しめ】",
          url: "/second-half-of-exam-2020/",
        },
      ],
      [
        {
          title:
            "【教員になるためには？】必要な教員免許から採用試験まで解説します",
          url: "/how-to-become-a-teacher/",
        },
        {
          title: "マルッとわかる。教員採用試験のスケジュールとその内容",
          url: "/test-schedule-and-content/",
        },
        {
          title: "【教員採用試験対策】まず何から始めればいい？",
          url: "/where-to-start-for-employment-exam/",
        },
        {
          title: "【教員採用試験】不合格のその後を考える【メリットもあります】",
          url: "/failure-in-teacher-recruitment-exam/",
        },
      ],
      [
        {
          title: "前置詞 aboutのイメージと使い方【例文あり】",
          url: "/preposition-about/",
        },
        {
          title: "前置詞 aboveのイメージと使い方【例文あり】",
          url: "/preposition-above/",
        },
        {
          title: "前置詞 acrossのイメージと使い方【例文あり】",
          url: "/preposition-across/",
        },
        {
          title: "前置詞 afterのイメージと使い方【例文あり】",
          url: "/preposition-after/",
        },
        {
          title: "前置詞 alongのイメージと使い方【例文あり】",
          url: "/preposition-along/",
        },
        {
          title: "前置詞 againstのイメージと使い方【例文あり】",
          url: "/preposition-against/",
        },
        {
          title: "前置詞 amongのイメージと使い方【例文あり】",
          url: "/preposition-among/",
        },
        {
          title: "前置詞 aroundのイメージと使い方【例文あり】",
          url: "/preposition-around/",
        },
        {
          title: "前置詞 atのイメージと使い方【例文あり】",
          url: "/preposition-at/",
        },
        {
          title: "前置詞 beforeのイメージと使い方【例文あり】",
          url: "/preposition-before/",
        },
        {
          title: "前置詞 behindのイメージと使い方【例文あり】",
          url: "/preposition-behind/",
        },
        {
          title: "前置詞 betweenのイメージと使い方【例文あり】",
          url: "/preposition-between/",
        },
        {
          title: "前置詞 beyondのイメージと使い方【例文あり】",
          url: "/preposition-beyond/",
        },
        {
          title: "前置詞 byのイメージと使い方【例文あり】",
          url: "/preposition-by/",
        },

        {
          title: "前置詞 duringのイメージと使い方【例文あり】",
          url: "/preposition-during/",
        },
        {
          title: "前置詞 forのイメージと使い方【例文あり】",
          url: "/preposition-for/",
        },
        {
          title: "前置詞 fromのイメージと使い方【例文あり】",
          url: "/preposition-from/",
        },
        {
          title: "前置詞 inのイメージと使い方【例文あり】",
          url: "/preposition-in/",
        },
        {
          title: "前置詞 intoのイメージと使い方【例文あり】",
          url: "/preposition-into/",
        },
        {
          title: "前置詞 ofのイメージと使い方【例文あり】",
          url: "/preposition-of/",
        },

        {
          title: "前置詞 onのイメージと使い方【例文あり】",
          url: "/preposition-on/",
        },
        {
          title: "前置詞 ontoのイメージと使い方【例文あり】",
          url: "/preposition-onto/",
        },
        {
          title: "前置詞 overのイメージと使い方【例文あり】",
          url: "/preposition-over/",
        },
        {
          title: "前置詞 toのイメージと使い方【例文あり】",
          url: "/preposition-to/",
        },
        {
          title: "前置詞 toward(s)のイメージと使い方【例文あり】",
          url: "/preposition-towards/",
        },
        {
          title: "前置詞 throughのイメージと使い方【例文あり】",
          url: "/preposition-through/",
        },
        {
          title: "前置詞 underのイメージと使い方【例文あり】",
          url: "/preposition-under/",
        },
        {
          title: "前置詞 withのイメージと使い方【例文あり】",
          url: "/preposition-with/",
        },
        {
          title: "前置詞 withoutのイメージと使い方【例文あり】",
          url: "/preposition-without/",
        },
        {
          title: "前置詞 withinのイメージと使い方【例文あり】",
          url: "/preposition-within/",
        },

        {
          title: "副詞 awayのイメージと使い方【例文あり】",
          url: "/adverb-away/",
        },
        {
          title: "副詞 downのイメージと使い方【例文あり】",
          url: "/adverb-down/",
        },
        {
          title: "副詞 offのイメージと使い方【例文あり】",
          url: "/adverb-off/",
        },
        {
          title: "副詞 outのイメージと使い方【例文あり】",
          url: "/adverb-out/",
        },
        { title: "副詞 upのイメージと使い方【例文あり】", url: "/adverb-up/" },
      ],
      [
        {
          title:
            "在学中に留学したいなら知っておこう【費用・準備・申し込み手順】",
          url: "/preparation-for-studying-abroad/",
        },
        {
          title: "【アメリカ留学体験記】１日の流れから授業の様子まで紹介します",
          url: "/experience-note-in-america/",
        },
        {
          title:
            "【留学で鬱？】英語の勉強に行き詰まったら【スランプ時の対処法】",
          url: "/stuck-in-a-slump/",
        },
        {
          title: "アメリカ留学での恥ずかしい失敗談７選",
          url: "/failure-stories-of-studying-in-america/",
        },
      ],
      [
        {
          title: "クラスをデザインする【学級運営の方法】",
          url: "/designing-classroom/",
        },
        {
          title: "学級通信の作り方【テンプレートあり】",
          url: "/how-to-make-class-news/",
        },
        {
          title: "学級崩壊は新学期の３日間で決まる【原因と対策をポイント解説】",
          url: "/avoiding-breakdown/",
        },
        {
          title:
            "【スタバから学ぶ!!】教室掲示物の作成術【サンキューカードの作り方を紹介】",
          url: "/thank-you-card/",
        },
        {
          title: "生徒から信頼される教師になるために必要な２つのポイント",
          url: "/to-be-a-reliable-teacher/",
        },
        {
          title: "４月から初担任！入学式で失敗しないためには事前準備が超重要",
          url: "/new-teacher-prepares-for-entrance-ceremony/",
        },
        {
          title: "【新米教師必見】生徒と接するときの注意【TOP 10】",
          url: "/pay-attention-to-talk-with-students/",
        },
        {
          title:
            "【定期テストに向けて】学習計画表の使い方を解説【ダウンロード可】",
          url: "/how-to-use-study-plan/",
        },
        {
          title: "保護者面談を乗り切るための準備・注意事項を紹介！！",
          url: "/parents-teacher-meeting/",
        },
      ],
    ]

    return (
      <ThemeProvider theme={theme}>
        <SEO title="" />
        {/* <Helmet>

        </Helmet> */}
        <Header title={title} location={location} />
        <Wrapper>
          {cardContents.map((cardContent, index) => {
            return (
              <Content
                onClick={() =>
                  this.setState({
                    isOpen: true,
                    cardContent: cardContent,
                    postContent: postContents[index],
                  })
                }
                key={cardContent.title}
              >
                {/* <Card
                  src={cardContent.img}
                  background={cardContent.background}
                /> */}
                <Card
                  src={cardContent.img}
                  className="card"
                  background={cardContent.background}
                  width={150}
                  height={230}
                  alt={cardContent.title}
                />
                <Title>{cardContent.title}</Title>
                <Description>{cardContent.description}</Description>
              </Content>
            )
          })}
        </Wrapper>

        <ModalWindow
          isOpen={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          img={this.state.cardContent.img}
          cardTitle={this.state.cardContent.title}
          cardDescription={this.state.cardContent.description}
          background={this.state.cardContent.background}
          postContent={this.state.postContent}
        />

        <Footer />
        <MobileFooter isLibrary={isLibrary} />
        <GlobalStyle />
      </ThemeProvider>
    )
  }
}

export default Library

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
