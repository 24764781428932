import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default ({ filename, alt, width, height }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(width: 630)
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageNode = data.images.edges.find((n) =>
        n.node.relativePath.includes(filename)
      )

      if (!imageNode) {
        return null
      }

      const image = getImage(imageNode.node.childImageSharp)

      return (
        <GatsbyImage
          alt={alt}
          image={image}
          style={{
            objectFit: "cover",
            width: `${width}`,
            height: `${height}`,
            borderRadius: "8px",
          }}
        />
      )
    }}
  />
)
