import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Image from "./Image"

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0%;
  display: block;
  background-color: ${(props) => props.background};
  width: 100vw;
  height: 100vh;
  z-index: 20;

  animation-name: popup;
  animation-duration: 0.2s;
  animation-timing-function: ease;

  @keyframes popup {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Wrapper = styled.div`
  padding: 0 20px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  height: 100%;
`

const Btn = styled.button`
  color: #fff;
  font-size: 3em;
`

const CardImg = styled.img`
  display: block;
  margin: 20px auto 20px;
  width: 160px;
  border-radius: 10px;
`

const CardTitle = styled.h2`
  font-size: 1.7em;
  text-align: center;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: bold;
  letter-spacing: 0.2em;
  color: #fff;
`

const CardDescription = styled.p`
  font-size: 12px;
  padding: 10px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
`

const LinkPostCard = styled.div`
  display: flex;
  padding: 10px 15px;
  margin-bottom: 15px;
  width: 100%;
  /* border-radius: 7px;
  border: 1px solid #fff;
  background: rgba(255, 255, 255, 0.1); */

  color: #fff;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: bold;
`

class ModalWindow extends React.Component {
  render() {
    if (!this.props.isOpen) {
      return null
    }
    const postContent = this.props.postContent ? this.props.postContent : null

    // const filename = "子どもの塾は個別か集団か【元塾講師がぶっちゃけます】"

    console.log(this.props.postContent)

    return (
      <Modal
        className={this.props.isOpen ? "opened" : "closed"}
        background={this.props.background}
      >
        <Wrapper>
          <Btn onClick={this.props.onClose}>×</Btn>
          <CardImg src={this.props.img} />
          <CardTitle>{this.props.cardTitle}</CardTitle>
          <CardDescription>{this.props.cardDescription}</CardDescription>

          {postContent.map((post, index) => {
            return (
              <LinkPostCard key={`${post.title}+${index}`}>
                <Image
                  filename={`${post.url.slice(1)}0.jpg`}
                  alt={post.title}
                  width="100px"
                  height="100px"
                  style={{ borderRadius: "10px" }}
                />
                <Link
                  to={post.url}
                  style={{
                    fontSize: "13px",
                    color: "#fff",
                    flex: 1,
                    padding: "0 0 0 15px",
                  }}
                >
                  {post.title}
                </Link>
              </LinkPostCard>
            )
          })}
        </Wrapper>
      </Modal>
    )
  }
}

export default ModalWindow
